.App {
  text-align: center;
}

.AppContainer {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.payVendorLink {
  color: #09d3ac;
  border-style: none;
  font-size: large;
  margin: 20px;
}

.priceUnitJPY {
  font-size: smaller;
}
